import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import './DropdownEdit.css';

export default class DropdownEdit extends Component<
{
  items: {key: string, name: string}[],
  onChange: (key: string) => void,
  preselected?: string
}> {
  onChange(event: any) {
    this.props.onChange(event.target.value);
  }

  render() {
    const options = this.props.items.map(({ key, name }) => (
      <option key={key} className="select-option" value={key}>{name}</option>
    ));

    return (
      <div className="centered">
        <Form.Control
          as="select"
          className="select-dropdown"
          onChange={(e) => this.onChange(e)}
          defaultValue={this.props.preselected}
        >
          {options}
        </Form.Control>
      </div>
    );
  }
}
