const prefix = 'sessionLinks/';

export const ADD_SESSION_LINK = `${prefix}ADD_SESSION_LINK`;
export const SET_SESSION_LINKS = `${prefix}SET_SESSION_LINKS`;
export const CLEAR_SESSION_LINKS = `${prefix}CLEAR_SESSION_LINKS`;

export interface SessionLink {
  sessionId: string;
  name: string;
}

export interface LinksStorageState {
  links: SessionLink[];
}

export interface AddSessionLinkAction {
  type: typeof ADD_SESSION_LINK;
  link: SessionLink;
}

export interface SetSessionLinksAction {
  type: typeof SET_SESSION_LINKS;
  links: SessionLink[];
}

export interface ClearSessionLinksAction {
  type: typeof CLEAR_SESSION_LINKS;
}

export type LinkActionTypes =
  | AddSessionLinkAction
  | ClearSessionLinksAction
  | SetSessionLinksAction;
