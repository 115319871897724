import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Expense, User } from '../../redux/app/model';
import { RootState } from '../../redux/store';
import DebtGraph from '../../utils/graphs';
import './BalanceTab.css';

interface Balance {
  from: User;
  to: User;
  amount: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BalanceRow = ({ balance } : {balance: Balance}) => {
  const b = balance;
  return (
    <tr>
      <td>{b.from.name}</td>
      <td>
        <span className="amount-class">
          {b.amount.toFixed(2)}
          {' '}
          zł
        </span>
      </td>
      <td>{b.to.name}</td>
    </tr>
  );
};

const BalanceTable = ({ balance } : {balance: Balance[]}) => (
  <div>
    <div style={{ textAlign: 'center', margin: '30px 10px 10px 10px' }}>
      Aby rozliczyć wpisane wydatki, wystarczy wykonać takie przelewy:
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
      <table className="expenses-table balance-table">
        <thead>
          <tr>
            <th>Od</th>
            <th>Ile</th>
            <th>Do</th>
          </tr>
        </thead>
        <tbody>
          {balance.map((b) => (
            <BalanceRow balance={b} key={b.from.userId + b.to.userId} />
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

/* <REDUX> */
const mapStateToProps = (state: RootState) => ({
  session: state.session,
  expenses: state.expenses,
});
const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector>;
/* </REDUX> */

class BalanceTab extends Component<Props> {
  static expensesToBalance(users: User[], expenses: Expense[]): Balance[] {
    const usersNum = users.length;
    const findUserI = (userId: string) => users.findIndex((e) => e.userId === userId);

    const graph = new DebtGraph(usersNum);

    // Add expenses to DebtGraph
    for (let i = 0; i < expenses.length; i += 1) {
      const expense = expenses[i];
      const payingUser = findUserI(expense.payingUser.userId);

      // each user has to pay that much
      const contribution = expense.amount / expense.contributorIds.length;

      for (let j = 0; j < expense.contributorIds.length; j += 1) {
        const contributorIndex = findUserI(expense.contributorIds[j]);
        graph.addDebt(contributorIndex, payingUser, contribution);
      }
    }

    // magic happening here
    graph.removeCyclicDebt();

    // map results back to our users
    const res = graph.getDebt().map((e) => {
      if (e.weight > 0) {
        return { from: users[e.from], to: users[e.to], amount: e.weight };
      }
      return { from: users[e.to], to: users[e.from], amount: -e.weight };
    });

    return res;
  }

  render() {
    const b = BalanceTab.expensesToBalance(
      this.props.session.users,
      this.props.expenses.expenses,
    );

    // <WORKAROUND>: when deleting user, there is a moment before full refresh
    //               when locally (on client) expenses exist with paying_user that does not exist.
    //               I think it happens after receiving session and before reveiving expenses
    const broken = b.some((obj) => obj.from === undefined || obj.to === undefined);
    if (broken) return null;

    // </WORKAROUND>
    return <BalanceTable balance={b} />;
  }
}

export default connector(BalanceTab);
