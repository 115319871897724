import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './LogoPanel.css';

const LogoPanel = () => (
  <Row style={{ backgroundColor: 'var(--color-1)', color: 'white', alignItems: 'center' }}>
    <Col xs={2} />
    <Col xs={1}>
      <div className="skewed-box">
        <div style={{ transform: 'skew(30deg)' }}>
          <a href="/" className="link-no-link">
            <img src="../assets/logo.svg" alt="LOGO" height={36} />
            <span style={{ color: 'var(--color-5)', paddingLeft: '10px' }}>SaveTab</span>
          </a>
        </div>
      </div>
    </Col>
    <Col xs={{ span: 1, offset: 6 }}><a href="https://vprocess.pl">O nas</a></Col>
  </Row>
);

export default LogoPanel;
