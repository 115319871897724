import {
  LinkActionTypes,
  SessionLink,
  ADD_SESSION_LINK,
  CLEAR_SESSION_LINKS,
  SET_SESSION_LINKS,
} from './types';

export function addSessionLink(link: SessionLink): LinkActionTypes {
  return {
    type: ADD_SESSION_LINK,
    link,
  };
}

export function setSessionLinks(links: SessionLink[]): LinkActionTypes {
  return {
    type: SET_SESSION_LINKS,
    links,
  };
}

export function clearSessionLinks(): LinkActionTypes {
  return {
    type: CLEAR_SESSION_LINKS,
  };
}
