import { TransferState } from '../../../utils/backendUtil';
import { Session, User } from '../model';

const prefix = 'session/';

// Session
export const FETCH_SESSION_REQUEST = `${prefix}FETCH_SESSION_REQUEST`;
export const FETCH_SESSION_FAILURE = `${prefix}FETCH_SESSION_FAILURE`;
export const FETCH_SESSION_SUCCESS = `${prefix}FETCH_SESSION_SUCCESS`;

export interface FetchSessionRequestAction {
  type: typeof FETCH_SESSION_REQUEST;
  sessionId: string;
}

export interface FetchSessionFailureAction {
  type: typeof FETCH_SESSION_FAILURE;
  sessionId: string;
}

export interface FetchSessionSuccessAction {
  type: typeof FETCH_SESSION_SUCCESS;
  sessionId: string;
  users: User[];
  name: string;
  description: string;
}

// session create
export const CREATE_SESSION_REQUEST = `${prefix}CREATE_SESSION_REQUEST`;
export const CREATE_SESSION_FAILURE = `${prefix}CREATE_SESSION_FAILURE`;
export const CREATE_SESSION_SUCCESS = `${prefix}CREATE_SESSION_SUCCESS`;

export interface CreateSessionRequestAction {
  type: typeof CREATE_SESSION_REQUEST;
  name: string;
  description: string;
}

export interface CreateSessionFailureAction {
  type: typeof CREATE_SESSION_FAILURE;
}

export interface CreateSessionSuccessAction {
  type: typeof CREATE_SESSION_SUCCESS;
  sessionId: string;
}

// Updating session
export const UPDATE_SESSION_REQUEST = `${prefix}UPDATE_SESSION_REQUEST`;
export const UPDATE_SESSION_FAILURE = `${prefix}UPDATE_SESSION_FAILURE`;
export const UPDATE_SESSION_SUCCESS = `${prefix}UPDATE_SESSION_SUCCESS`;

export interface UpdateSessionRequestAction {
  type: typeof UPDATE_SESSION_REQUEST;
  expenseId: string;
  data: any;
}

export interface UpdateSessionFailureAction {
  type: typeof UPDATE_SESSION_FAILURE;
}

export interface UpdateSessionSuccessAction {
  type: typeof UPDATE_SESSION_SUCCESS;
  session: Session;
}

// Creating users
export const CREATE_USER_REQUEST = `${prefix}CREATE_USER_REQUEST`;
export const CREATE_USER_FAILURE = `${prefix}CREATE_USER_FAILURE`;
export const CREATE_USER_SUCCESS = `${prefix}CREATE_USER_SUCCESS`;

export interface CreateUserRequestAction {
  type: typeof CREATE_USER_REQUEST;
  name: string;
  sessionId: string;
}

export interface CreateUserFailureAction {
  type: typeof CREATE_USER_FAILURE;
}

export interface CreateUserSuccessAction {
  type: typeof CREATE_USER_SUCCESS;
  user: User;
}

// removing users
export const REMOVE_USER_REQUEST = `${prefix}REMOVE_USER_REQUEST`;
export const REMOVE_USER_FAILURE = `${prefix}REMOVE_USER_FAILURE`;
export const REMOVE_USER_SUCCESS = `${prefix}REMOVE_USER_SUCCESS`;

export interface RemoveUserRequestAction {
  type: typeof REMOVE_USER_REQUEST;
  userId: string;
}

export interface RemoveUserFailureAction {
  type: typeof REMOVE_USER_FAILURE;
}

export interface RemoveUserSuccessAction {
  type: typeof REMOVE_USER_SUCCESS;
  userId: string;
}

export type SessionActionTypes =
  | FetchSessionRequestAction
  | FetchSessionFailureAction
  | FetchSessionSuccessAction
  | CreateSessionRequestAction
  | CreateSessionFailureAction
  | CreateSessionSuccessAction
  | UpdateSessionRequestAction
  | UpdateSessionFailureAction
  | UpdateSessionSuccessAction
  | CreateUserRequestAction
  | CreateUserFailureAction
  | CreateUserSuccessAction
  | RemoveUserRequestAction
  | RemoveUserFailureAction
  | RemoveUserSuccessAction;

export interface SessionState {
  networkState: TransferState;
  sessionId: string;
  users: User[]; // TODO: extract users to separate store?
  name: string;
  description: string;
}
