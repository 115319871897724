import { UpdateSessionSuccessAction, UPDATE_SESSION_SUCCESS } from '../session';
import {
  LinksStorageState,
  LinkActionTypes,
  ADD_SESSION_LINK,
  SET_SESSION_LINKS,
  CLEAR_SESSION_LINKS,
  SessionLink,
  AddSessionLinkAction,
  SetSessionLinksAction,
} from './types';

const INITIAL_STATE: LinksStorageState = {
  links: [],
};

const sessionsLinksReducer = (
  state: LinksStorageState = INITIAL_STATE,
  action: LinkActionTypes,
): LinksStorageState => {
  const contains = (sl: SessionLink): boolean => {
    for (let i = 0; i < state.links.length; i += 1) {
      if (state.links[i].sessionId === sl.sessionId) {
        return true;
      }
    }
    return false;
  };

  switch (action.type) {
    case ADD_SESSION_LINK:
    {
      const action_ = action as AddSessionLinkAction;
      if (!contains(action_.link)) {
        return { links: [...state.links, { ...action_.link }] };
      }
      return state;
    }

    case SET_SESSION_LINKS:
    {
      const action_ = action as SetSessionLinksAction;
      return {
        links: [...action_.links],
      };
    }

    case CLEAR_SESSION_LINKS:
      return { links: [] };

    case UPDATE_SESSION_SUCCESS:
    {
      const action_ = action as UpdateSessionSuccessAction;

      const stateCopy = { ...state };
      for (let i = 0; i < stateCopy.links.length; i += 1) {
        if (stateCopy.links[i].sessionId === action_.session.sessionId) {
          stateCopy.links[i].name = action_.session.name;
          break;
        }
      }

      return stateCopy;
    }

    default:
      return state;
  }
};

export default sessionsLinksReducer;
