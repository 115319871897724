import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import sessionReducer from './session';
import expesesReducer from './expenses';
import sessionsLinksReducer from './sessionLinks';

const createRootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  session: sessionReducer,
  expenses: expesesReducer,
  sessionLinks: sessionsLinksReducer,
});

export default createRootReducer;
