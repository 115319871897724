import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LogoPanel from './common/LogoPanel';

const Error404Screen = () => (
  <Container fluid>
    <LogoPanel />

    <Row>
      <Container className="inner-container">
        <Row style={{ marginTop: '200px' }} />

        <Row className="center-piece-box">
          <Col>
            <div style={{
              display: 'flex', alignItems: 'center', flexFlow: 'column', margin: '150px',
            }}
            >
              <div style={{ flex: '1', fontSize: '80px' }}>Błąd 404</div>
              <div style={{ flex: '1', fontSize: '30px' }}>Nie odnaleziono tego adresu!</div>
            </div>
          </Col>

        </Row>

        <Row style={{ height: '50px' }} />
      </Container>
    </Row>

  </Container>
);

export default Error404Screen;
