import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { setSessionLinks, clearSessionLinks } from '../../redux/app/sessionLinks';
import { createSession } from '../../redux/app/session';
import { RootState } from '../../redux/store';
import LinksList from './LinksList';

/* <REDUX> */
const mapStateToProps = (state: RootState) => ({
  lss: state.sessionLinks,
});
const mapDispachToProps = {
  createSession,
  setSessionLinks,
  clearSessionLinks,
};
const connector = connect(mapStateToProps, mapDispachToProps);
type Props = ConnectedProps<typeof connector>;
/* </REDUX> */

export class SessionLinksBar extends Component<Props> {
  async createNewSession() {
    this.props.createSession('Nowa sesja', 'Kliknij by edytować opis!');
  }

  render() {
    return (
      <LinksList
        links={this.props.lss.links}
        onClear={() => this.props.clearSessionLinks()}
        onCreate={() => this.createNewSession()}
        onReorder={(links) => this.props.setSessionLinks(links)}
      />
    );
  }
}

export default connector(SessionLinksBar);
