/* eslint-disable max-classes-per-file */
/* eslint-disable no-alert */

import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createUser, removeUser } from '../../redux/app/session';
import { User } from '../../redux/app/model';
import InlineEdit from '../common/InlineEdit';
import './UserTab.css';
import { RootState } from '../../redux/store';

class AddNewInlineEdit extends Component<{sessionId: string, createUser: any}> {
  state = {
    text: '',
  };

  addUser(name: string) {
    this.props.createUser(this.props.sessionId, name);
  }

  render() {
    return (
      <tr key="add-new" className="add-new-row">
        <td className="custom-inline" style={{ textAlign: 'left' }}>
          <InlineEdit text={this.state.text} onSetText={(t) => this.setState({ text: t })} minWidth={10} />
        </td>
        <td>
          <button className="button-no-button" type="button" onClick={() => this.addUser(this.state.text)}>
            <img src="../assets/plus.svg" width="20px" alt="add" />
          </button>
        </td>
      </tr>
    );
  }
}

/* <REDUX> */
const mapStateToProps = (state: RootState) => ({
  session: state.session,
});
const mapDispachToProps = {
  createUser,
  removeUser,
};
const connector = connect(mapStateToProps, mapDispachToProps);
type Props = ConnectedProps<typeof connector>;
/* </REDUX> */

class UserTab extends Component<{ users: User[] } & Props> {
  removeUser(id: string) {
    // eslint-disable-next-line max-len
    if (window.confirm('To spowoduje usunięcie użytkownika oraz wszystkich wydatków za które płacił. Kontynuować?')) {
      this.props.removeUser(this.props.session.sessionId, id);
    }
  }

  render() {
    return (
      <div>
        <div style={{ textAlign: 'center', margin: '30px 10px 10px 10px' }}>
          Dodaj imiona użytkowników uczestniczących w rozliczeniu.
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <table className="expenses-table users-table">
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>Imie</th>
                <th style={{ width: '70px' }}>Usuń</th>
              </tr>
            </thead>
            <tbody>
              {this.props.users.map((u) => (
                <tr key={u.userId}>
                  <td>{u.name}</td>
                  <td>
                    <button className="button-no-button" type="button" onClick={() => this.removeUser(u.userId)}>
                      <img src="../assets/thrashb.svg" width="20px" alt="thrash" />
                    </button>
                  </td>
                </tr>
              ))}
              <AddNewInlineEdit sessionId={this.props.session.sessionId} createUser={this.props.createUser} />
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default connector(UserTab);
