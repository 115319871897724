/* eslint-disable max-len */
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import Typist from 'react-typist';
import { createSession } from '../redux/app/session';
import './MainScreen.css';

/* <REDUX> */
const mapDispachToProps = {
  createSession,
};
const connector = connect(undefined, mapDispachToProps);
type Props = ConnectedProps<typeof connector>;
/* </REDUX> */

export class MainScreen extends Component<Props> {
  createNewSession() {
    this.props.createSession('Nowa sesja', 'Kliknij by edytować opis!').then(
      (e) => { window.location.href = `/session/${e}`; },
    );
  }

  render() {
    return (
      <div>

        <div id="logo-panel">
          <Row style={{ margin: '0px' }}>
            <div
              className="skewed-box"
              style={{ marginLeft: '15%' }}
            >
              <div style={{ transform: 'skew(30deg)' }}>
                <img src="../assets/logo.svg" alt="LOGO" height={36} />
                <span style={{ paddingLeft: '10px' }}>SaveTab</span>
              </div>
            </div>
            <Col xs={{ span: 1, offset: 6 }} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <a href="https://vprocess.pl">O nas</a>
            </Col>
          </Row>
        </div>

        <div id="first-panel">

          <div
            className="background-image"
              // eslint-disable-next-line max-len
            style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),  url('${process.env.PUBLIC_URL}/assets/hero2.jpg')` }}
          >
            <Container>
              <Row style={{ marginTop: '15%' }} className="typist-text">
                <Col>
                  <div style={{ height: '180px' }}>
                    <Typist avgTypingDelay={70}>
                      Wygodnie dziel paragony
                      <br />
                      między przyjaciółmi...
                    </Typist>
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: '100px' }}>
                <Col xs={{ span: 4, offset: 8 }} style={{ textAlign: 'center' }}>
                  <button type="button" className="hero-button" onClick={() => this.createNewSession()}>Stwórz sesję</button>
                </Col>
              </Row>

              <Row style={{ marginTop: '100px' }}>
                <Col xs={7}>
                  <div className="user-review-box">
                    <div className="user-pic">
                      <img style={{ borderRadius: '50%', boxShadow: '0 0px 10px rgba(0, 0, 0, 0.7)' }} src="../assets/user1.jpg" alt="Adam" width="100px" />
                    </div>
                    <div className="user-review">
                      <div>
                        &quot;Doskonałe rozwiązanie, pozwoliło nam na błyskawiczne rozliczenie się po tygodniowym wyjeździe w góry!
                        <br />
                        Polecam każdemu.&quot;
                        <br />
                        <span style={{ marginLeft: '100px' }}>~ Adam z Gliwic (24 l.)</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={5} />
              </Row>

            </Container>

          </div>

        </div>
        <div className="second-panel" style={{ marginTop: '-20px', marginBottom: '-10px' }} />

      </div>
    );
  }
}

export default connector(MainScreen);
