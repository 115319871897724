/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable func-names */
import {
  Expense,
} from '../model';
import {
  ExpensesActionTypes,
  FETCH_EXPENSES_SUCCESS,
  FETCH_EXPENSES_REQUEST,
  CREATE_EXPENSE_REQUEST,
  CREATE_EXPENSE_SUCCESS,
  UPDATE_EXPENSE_REQUEST,
  UPDATE_EXPENSE_SUCCESS,
  REMOVE_EXPENSE_REQUEST,
  REMOVE_EXPENSE_SUCCESS,
} from './types';
import ApiClient from '../../../utils/apiClient';

// create expense
function requestExpenseCreation(
  expense: Expense,
  sessionId: string,
): ExpensesActionTypes {
  return {
    type: CREATE_EXPENSE_REQUEST,
    expense,
    sessionId,
  };
}

function receiveCreatedExpense(expense: Expense): ExpensesActionTypes {
  return {
    type: CREATE_EXPENSE_SUCCESS,
    expense,
  };
}

// Thunk action creator
export function createExpense(expense: Expense, sessionId: string) {
  return function (dispatch: any) {
    dispatch(requestExpenseCreation(expense, sessionId));

    return ApiClient.createExpense(expense, sessionId).then((response) => {
      dispatch(receiveCreatedExpense(response.data));
    });
  };
}

// remove user
function requestExpenseRemoval(
  expenseId: string,
): ExpensesActionTypes {
  return {
    type: REMOVE_EXPENSE_REQUEST,
    expenseId,
  };
}

function receiveRemovedExpense(expenseId: string): ExpensesActionTypes {
  return {
    type: REMOVE_EXPENSE_SUCCESS,
    expenseId,
  };
}

// Thunk action creator
export function removeExpense(expenseId: string) {
  return function (dispatch: any) {
    dispatch(requestExpenseRemoval(expenseId));

    return ApiClient.removeExpense(expenseId).then((response) => {
      dispatch(receiveRemovedExpense(expenseId));
    });
  };
}

// Fetch all expenses from backend
function requestExpenses(sessionId: string): ExpensesActionTypes {
  return {
    type: FETCH_EXPENSES_REQUEST,
  };
}

function receiveExpenses(expenses: Expense[]): ExpensesActionTypes {
  return {
    type: FETCH_EXPENSES_SUCCESS,
    expenses,
  };
}

// Thunk action creator
export function fetchExpenses(sessionId: string) {
  return function (dispatch: any) {
    dispatch(requestExpenses(sessionId));

    return ApiClient
      .readExpenses(sessionId)
      .then((response) => dispatch(receiveExpenses(response.data)));
  };
}

// Update single expense
function requestExpenseUpdate(expenseId: string, data: any): ExpensesActionTypes {
  return {
    type: UPDATE_EXPENSE_REQUEST,
    expenseId,
    data,
  };
}

function expenseUpdateSuccess(expense: Expense): ExpensesActionTypes {
  return {
    type: UPDATE_EXPENSE_SUCCESS,
    expense,
  };
}

export function updateExpense(expenseId: string, data: any) {
  return function (dispatch: any) {
    dispatch(requestExpenseUpdate(expenseId, data));

    return ApiClient
      .updateExpense(expenseId, data)
      .then((response) => dispatch(expenseUpdateSuccess(response.data)));
  };
}
