/* eslint-disable no-cond-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-continue */
/* eslint-disable no-param-reassign */
interface Edge {
  from: number;
  to: number;
  weight: number;
}
/**
 * Algorithm:
 * 1. convert expenses to weighted graph
 *   - nodes = users
 *   - edges = amount of borrowed money
 * 2. find cycle in a graph (DFS algo) or if cannot find end
 * 3. find smallest value in that path and subtract it from every edge on that path
 *    effectively removing an edge from this graph
 *
 * Usage:
 * let a = new DebtGraph(3);
 * a.addDebt(0,1,100);
 * a.addDebt(1,2,100);
 * console.log(a.getDebt());
 *
 * implementation note:
 * I assume that edges are unique and always sorted ("from" < "to" for every edge)
 */
export default class DebtGraph {
  private nodesNum: number;

  private edges: Edge[] = [];

  private precision = 0.00001;

  private findEdgeIndex(from: number, to: number) {
    // ensure from and to are sorted
    if (from > to) {
      // swap those numbers
      [to, from] = [from, to];
    }
    return this.edges.findIndex((e) => e.from === from && e.to === to);
  }

  /**
   * @param nodes number of nodes that will participate in this debt settlement
   */
  constructor(nodes: number) {
    this.nodesNum = nodes;
  }

  addDebt(from: number, to: number, weight: number) {
    if (from === to) return; // self-debt is automatically paid, no need for edge
    if (weight === 0) return; // also not a debt

    // ensure from and to are sorted
    if (from > to) {
      // swap those numbers
      [to, from] = [from, to];
      weight = -weight;
    }

    const existingIndex = this.findEdgeIndex(from, to);

    if (existingIndex === -1) { // edge does not exist
      this.edges.push({ from, to, weight });
    } else {
      this.edges[existingIndex].weight += weight;
      // check if weight is zero (with precision)
      if (Math.abs(this.edges[existingIndex].weight) <= this.precision) {
        // remove this edge completely
        this.edges.splice(existingIndex, 1);
      }
    }
  }

  private findCycleRecursive(node: number, visited: boolean[], parentNode: number): number[] | false {
    visited[node] = true; // mark this node as visited

    // iterate over all edges to find those connected to this node (any better way to do this?)
    for (let i = 0; i < this.edges.length; i += 1) {
      const edge = this.edges[i];

      let nextNode = -1;
      if (edge.to === node) nextNode = edge.from;
      if (edge.from === node) nextNode = edge.to;
      if (nextNode === -1) continue;

      if (!visited[nextNode]) {
        const ret = this.findCycleRecursive(nextNode, visited, node);
        if (ret !== false) {
          // somewhere down the stack a cycle was completed, append our node and return
          // stop appending nodes when rewinded stack reaches node that started the cycle

          // previous calls already reached cycle start so just return that path
          if (ret[0] === -1) return ret;

          // this node was hit as a cycle start; mark cycle as finished by returning -1 at front
          if (ret[0] === node) return [-1, ...ret];
          return [...ret, node];
        }
      } else if (nextNode !== parentNode) {
        // If an adjacent vertex is visited and not parent of current vertex,
        // then there is a cycle.
        // start returning path from that node that was previously visited
        return [nextNode, node];
      }
    }

    return false;
  }

  /**
   * Tries to find cycle in whole graph.
   * returns false on failure or cycle path like [1, 3, 5]
   */
  private findCycle() {
    const visited: boolean[] = new Array(this.nodesNum);
    visited.fill(false);

    for (let i = 0; i < this.nodesNum; i += 1) {
      if (!visited[i]) {
        const ret = this.findCycleRecursive(i, visited, -1);
        if (ret !== false) {
          ret.splice(0, 1);
          // drop first item that is -1 marking end of cycle (internal implementation detail)
          return ret;
        }
      }
    }
    return false;
  }

  private settleCyclicPath(path: number[]) {
    // add first node to the path end so we can iterate over every edge
    // in following fors
    path.push(path[0]);

    let a = path[0];
    let lowest = Infinity;
    let sign = 1;
    // step 1. find lowest abs value in the path
    for (let i = 1; i < path.length; i += 1) {
      const b = path[i];
      const { weight } = this.edges[this.findEdgeIndex(a, b)];
      if (Math.abs(weight) < lowest) {
        lowest = Math.abs(weight);
        sign = Math.sign(weight);
        if (a < b) sign *= -1;
      }
      a = b;
    }
    lowest *= sign;

    // step 2. subtract whole path from graph
    a = path[0];
    for (let i = 1; i < path.length; i += 1) {
      const b = path[i];
      if (a <= b) this.edges[this.findEdgeIndex(a, b)].weight += lowest;
      else this.edges[this.findEdgeIndex(a, b)].weight -= lowest;
      a = b;
    }

    // step 3. remove empty edges (there will be at least one)
    a = path[0];
    for (let i = 1; i < path.length; i += 1) {
      const b = path[i];
      const edgeI = this.findEdgeIndex(a, b);
      if (Math.abs(this.edges[edgeI].weight) <= this.precision) {
        this.edges.splice(edgeI, 1); // remove edge
      }
      a = b;
    }

    return lowest;
  }

  removeCyclicDebt() {
    let path: number[] | false;
    while ((path = this.findCycle()) !== false) {
      this.settleCyclicPath(path);
    }
  }

  getDebt() {
    return [...this.edges];
  }
}
