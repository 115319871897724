import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const WaitSpinner = ({ text } : {text?:string}) => (
  <Spinner animation="border" variant="light" role="status">
    <span className="sr-only">{text}</span>
  </Spinner>
);

WaitSpinner.defaultProps = {
  text: 'Loading ...',
};

export default WaitSpinner;
