import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { SessionLink } from '../../redux/app/sessionLinks';
import './LinksList.css';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Reorder = require('react-reorder');

const ListItem = ({ item } : { item: any }) => {
  const e = item;
  return (
    <div key={e.sessionId} className="inner session-link-item">
      <Row className="session-link">
        <span style={{ paddingRight: '1em' }}> &bull; </span>
        <a href={e.sessionId}>
          {e.name}
        </a>
      </Row>

    </div>
  );
};

export default class LinksList extends Component<{
  links: SessionLink[];
  onClear: () => void;
  onCreate: () => void;
  onReorder: (list: SessionLink[]) => void;
}> {
  callback(event: any, item: any, index: any, newIndex: any, list: any) {
    this.props.onReorder(list);
  }

  render() {
    return (
      <Container>
        <Row className="header">
          SESJE
          <button className="button-no-button" type="button" onClick={this.props.onClear}>
            <img src="../assets/thrash.svg" width={18} alt="Remove all" />
          </button>
        </Row>

        <Reorder
          itemKey="sessionId"
          holdTime="100"
          list={this.props.links}
          template={ListItem}
          // eslint-disable-next-line react/jsx-no-bind
          callback={this.callback.bind(this)}
          itemClass="list-item m-1"
        />
        <div className="centered">
          <button className="button-no-button create-session" type="button" onClick={this.props.onCreate}>
            Stwórz nową
          </button>
        </div>
      </Container>

    );
  }
}
