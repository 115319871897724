import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import WaitSpinner from '../common/WaitSpinner';
import PageView from './PageView';

import { fetchSession } from '../../redux/app/session/actions';
import { fetchExpenses } from '../../redux/app/expenses/actions';
import { RootState } from '../../redux/store';

/* <REDUX> */
const mapStateToProps = (state: RootState) => ({
  session: state.session,
  expenses: state.expenses,
});
const mapDispachToProps = {
  fetchSession,
  fetchExpenses,
};
const connector = connect(mapStateToProps, mapDispachToProps);
type Props = ConnectedProps<typeof connector> & { match: any };
/* </REDUX> */

class SessionScreen extends Component<Props> {
  async componentDidMount() {
    if (
      this.props.session.networkState.isFetched === false
      && this.props.expenses.networkState.isFetching === false
    ) {
      // TODO: sanitize ID? (maybe in router)
      this.props.fetchSession(this.props.match.params.id);
      this.props.fetchExpenses(this.props.match.params.id);
    }
  }

  render() {
    if (this.props.session.networkState.isFetched === false) return <WaitSpinner />;

    return <PageView />;
  }
}

export default connector(SessionScreen);
