import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './app/reducers';

const persistConfig = {
  key: 'sessionLinks',
  storage,
  whitelist: ['sessionLinks'],
};

export const history = createBrowserHistory();
const pReducer = persistReducer(persistConfig, rootReducer(history));

export const store = createStore(
  pReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware, routerMiddleware(history as any))),
);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>
