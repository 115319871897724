import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect, ConnectedProps } from 'react-redux';
import { Nav } from 'react-bootstrap';
import SessionLinksBar from './SessionLinksBar';
import ExpensesTab from './ExpensesTab';
import BalanceTab from './BalanceTab';
import UserTab from './UserTab';
import SessionHeader from './SessionHeader';
import WaitSpinner from '../common/WaitSpinner';
import { RootState } from '../../redux/store';
import './PageView.css';
import LogoPanel from '../common/LogoPanel';

/* <REDUX> */
const mapStateToProps = (state: RootState) => ({
  session: state.session,
  expenses: state.expenses,
});
const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector>;
/* </REDUX> */

const PageView = (props: Props) => {
  const activeTab = props.session.users.length === 0 ? 'users' : 'expenses';

  return (
    <Container fluid>
      <LogoPanel />

      <Row>
        <Container className="inner-container">
          <SessionHeader />

          <Row className="center-piece-box">
            <Col className="left-panel">
              <SessionLinksBar />
            </Col>

            <Col xs={10}>
              <Tab.Container defaultActiveKey={activeTab} id="uncontrolled-tab-session">
                <Row style={{ marginTop: '10px' }} className="centered">
                  <Nav variant="pills">
                    <Nav.Item className="navitem">
                      <Nav.Link eventKey="users">Członkowie</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="navitem">
                      <Nav.Link eventKey="expenses">Wydatki</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="navitem">
                      <Nav.Link eventKey="balance">Bilans</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Row>

                <Tab.Content>
                  <Tab.Pane eventKey="expenses">
                    {!props.expenses.networkState.isFetched ? (
                      <WaitSpinner />
                    ) : (
                      <ExpensesTab />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="balance">
                    {!props.expenses.networkState.isFetched ? (
                      <WaitSpinner />
                    ) : (
                      <BalanceTab />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="users">
                    <UserTab users={props.session.users} />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>

            </Col>

          </Row>

          <Row style={{ height: '50px' }} />
        </Container>
      </Row>

    </Container>
  );
};

export default connector(PageView);
