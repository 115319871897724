/* eslint-disable no-case-declarations */
import {
  ExpensesState,
  ExpensesActionTypes,
  FetchExpensesSuccessAction,
  UpdateExpenseSuccessAction,
  FETCH_EXPENSES_REQUEST,
  FETCH_EXPENSES_SUCCESS,
  UPDATE_EXPENSE_REQUEST,
  UPDATE_EXPENSE_SUCCESS,
  CREATE_EXPENSE_SUCCESS,
  CREATE_EXPENSE_REQUEST,
  CreateExpensSuccessAction,
  REMOVE_EXPENSE_SUCCESS,
  REMOVE_EXPENSE_REQUEST,
  RemoveExpenseSuccessAction,
} from './types';

const INITIAL_STATE: ExpensesState = {
  networkState: { isFetching: false, isFetched: false },
  expenses: [],
};

const expensesReducer = (
  state = INITIAL_STATE,
  action: ExpensesActionTypes,
): ExpensesState => {
  switch (action.type) {
    // expenses
    case FETCH_EXPENSES_REQUEST:
      return {
        // TODO: refactor expenses to be flat (normalized) there is a lib for that if necessary
        ...state,
        networkState: { isFetching: true, isFetched: false },
      };
    case FETCH_EXPENSES_SUCCESS:
    {
      const action_ = action as FetchExpensesSuccessAction;
      return {
        ...state,
        networkState: { isFetching: false, isFetched: true },
        expenses: [...action_.expenses],
      };
    }
    case UPDATE_EXPENSE_REQUEST:
      return state;
    case UPDATE_EXPENSE_SUCCESS:
    {
      const action_ = action as UpdateExpenseSuccessAction;
      const index = state.expenses.findIndex(
        (expenses) => expenses.expenseId === action_.expense.expenseId,
      );
      const newArray = [...state.expenses]; // making a new array
      newArray[index] = action_.expense;// changing value in the new array

      return {
        ...state,
        expenses: newArray,
      };
    }
    case CREATE_EXPENSE_SUCCESS:
    {
      const action_ = action as CreateExpensSuccessAction;
      return {
        ...state,
        expenses: [...state.expenses, action_.expense],
      };
    }
    case REMOVE_EXPENSE_SUCCESS:
    {
      const action_ = action as RemoveExpenseSuccessAction;
      const old = { ...state };
      const index = state.expenses.findIndex((v) => v.expenseId === action_.expenseId);
      if (index !== -1) {
        old.expenses.splice(index, 1);
        return {
          ...state,
          expenses: [...old.expenses],
        };
      }
      return state;
    }
    case CREATE_EXPENSE_REQUEST:
    case REMOVE_EXPENSE_REQUEST:
    default:
      return state;
  }
};

export default expensesReducer;
