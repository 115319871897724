import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import InlineEdit from '../common/InlineEdit';
import { updateSession } from '../../redux/app/session/actions';
import { RootState } from '../../redux/store';
import './SessionHeader.css';

/* <REDUX> */
const mapStateToProps = (state: RootState) => ({
  session: state.session,
});
const mapDispachToProps = {
  updateSession,
};
const connector = connect(mapStateToProps, mapDispachToProps);
type Props = ConnectedProps<typeof connector>;
/* </REDUX> */

class SessionHeader extends Component<Props> {
  updateField(field: string, value: any) {
    this.props.updateSession(this.props.session.sessionId, { [field]: value });
  }

  render() {
    return (
      <Row className="session-header">
        <Container>
          <Row style={{ height: '40px' }} />
          <Row>
            <Col />
            <Col>
              <div className="header-rounded-box">
                <InlineEdit
                  text={this.props.session.name}
                  onSetText={(text: string) => this.updateField('name', text)}
                />
              </div>
            </Col>
            <Col />
          </Row>
          <Row style={{ height: '20px' }} />
          <Row>
            <Col />
            <Col xs={9} className="header-description">
              <InlineEdit
                text={this.props.session.description}
                onSetText={(text: string) => this.updateField('description', text)}
                maxLength={200}
              />
            </Col>
            <Col />
          </Row>
        </Container>
      </Row>
    );
  }
}

export default connector(SessionHeader);
