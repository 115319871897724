import axios from 'axios';
import { Expense } from '../redux/app/model';

// const apiHost = 'http://localhost:5000';
const apiHost = 'https://api.savetab.io';

const BASE = `${apiHost}/api`;
const SESSION_ENDPOINT = `${BASE}/session`;

export default class ApiClient {
  static readSession(sessionId: string) {
    return axios.get(`${SESSION_ENDPOINT}/${sessionId}`);
  }

  static readExpenses(sessionId: string) {
    return axios.get(`${SESSION_ENDPOINT}/${sessionId}/expenses`);
  }

  static createSession(name: string, description: string) {
    return axios.post(
      `${SESSION_ENDPOINT}/`,
      {
        name,
        description,
      },
    );
  }

  static updateSession(sessionId: string, data: any) {
    return axios.put(
      `${SESSION_ENDPOINT}/${sessionId}`,
      data,
    );
  }

  static createExpense(expense: Expense, sessionId: string) {
    return axios.post(
      `${SESSION_ENDPOINT}/${sessionId}/expenses`,
      expense,
    );
  }

  static updateExpense(expenseId: string, data: any) {
    return axios.put(
      `${SESSION_ENDPOINT}/expense/${expenseId}`,
      data,
    );
  }

  static removeExpense(expenseId: string) {
    return axios.delete(
      `${SESSION_ENDPOINT}/expense/${expenseId}`,
    );
  }

  static createUser(sessionId: string, name: string) {
    return axios.post(
      `${SESSION_ENDPOINT}/${sessionId}/users`,
      {
        name,
      },
    );
  }

  static removeUser(userId: string) {
    return axios.delete(
      `${SESSION_ENDPOINT}/users/${userId}`,
    );
  }
}
