import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './App.css';

import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import SessionScreen from './components/SessionScreen';
import MainScreen from './components/MainScreen';
import Error404Screen from './components/Error404Screen';

import { store, persistor, history } from './redux/store';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function App() {
  return (

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <ConnectedRouter history={history as any}>
            <Switch>
              <Route path="/session/:id" component={SessionScreen} />
              <Route exact path="/" component={MainScreen} />
              <Route exact path="/404" component={Error404Screen} />
              <Redirect from="*" to="/404" />
            </Switch>
          </ConnectedRouter>
        </div>

      </PersistGate>
    </Provider>
  );
}

export default App;
