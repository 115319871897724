/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Checkbox from '../common/Checkbox';
import DropdownEdit from '../common/DropdownEdit';
import InlineEdit from '../common/InlineEdit';
import { createExpense, updateExpense, removeExpense } from '../../redux/app/expenses/actions';
import { RootState } from '../../redux/store';
import { Expense, User } from '../../redux/app/model';
import './ExpensesTab.css';

/* <REDUX> */
const mapStateToProps = (state: RootState) => ({
  session: state.session,
  expenses: state.expenses,
});
const mapDispachToProps = {
  createExpense,
  updateExpense,
  removeExpense,
};
const connector = connect(mapStateToProps, mapDispachToProps);
type Props = ConnectedProps<typeof connector>;
/* </REDUX> */

class AddNewInlineEdit extends Component<{ users: User[], createExpense: any, sessionId: string },
  { text: string, payingUser: string|undefined, contributors: string[], amount: string }> {
  constructor(p: any) {
    super(p);
    this.state = {
      text: '',
      payingUser: undefined,
      contributors: p.users.map((u: User) => u.userId),
      amount: '',
    };
  }

  addExpense() {
    // this can happen when someone has 0 users, then creates one, and immidiately creates an expense
    const paying = this.state.payingUser || this.props.users[0].userId;
    const newExpense = {
      name: this.state.text,
      payingUser:
      {
        userId: paying,
      },
      contributorIds: this.state.contributors,
      amount: Number(this.state.amount),
    };
    this.props.createExpense(newExpense, this.props.sessionId);
  }

  checkboxClicked(userId: string, newBool: boolean) {
    if (newBool === true) {
      // add to list
      this.setState((state) => {
        if (state.contributors.findIndex((v) => v === userId) === -1) {
          return { contributors: [...state.contributors, userId] };
        }
        return { contributors: [...state.contributors] };
      });
    } else {
      // remove from list
      this.setState((state) => {
        const i = state.contributors.findIndex((v) => v === userId);
        if (i !== -1) {
          state.contributors.splice(i, 1);
        }
        return { contributors: [...state.contributors] };
      });
    }
  }

  render() {
    // dont render if there are no users
    if (this.props.users.length === 0) {
      return null;
    }

    return (
      <tr key="add-new" className="add-new-row">
        <td>
          <button
            className="button-no-button"
            type="button"
            onClick={() => this.addExpense()}
            style={{ marginRight: '20px', marginLeft: '-5px', marginTop: '3px' }}
          >
            <img src="../assets/plus.svg" width="22px" alt="add" />
          </button>
        </td>
        <td className="custom-inline2" style={{ textAlign: 'left' }}>
          <InlineEdit text={this.state.text} onSetText={(t) => this.setState({ text: t })} minWidth={10} />
        </td>
        <td>
          <DropdownEdit
            items={this.props.users.map(({ userId, name }) => ({ key: userId, name }))}
            // eslint-disable-next-line react/no-unused-state
            onChange={(newId) => this.setState({ payingUser: newId })}
            preselected={this.props.users[0].userId}
          />
        </td>
        <td className="custom-inline2" style={{ textAlign: 'right' }}>
          <InlineEdit text={this.state.amount} onSetText={(t) => this.setState({ amount: t })} minWidth={4} />
          {' '}
          zł
        </td>
        {this.props.users.map((user) => (
          <td key={user.userId}>
            <Checkbox
              checked
              onChange={(v) => this.checkboxClicked(user.userId, v)}
            />
          </td>
        ))}
      </tr>
    );
  }
}

class ExpensesTab extends Component<Props> {
  updateField(expense: Expense, field: string, value: any) {
    if (field === 'payingUser') {
      this.props.updateExpense(expense.expenseId, { payingUser: { userId: value } });
    } else if (field === 'contributor') {
      // val = { userId: user.userId, newVal: v }
      if (value.newVal === true) {
        // add to list
        if (expense.contributorIds.findIndex((v) => v === value.userId) === -1) {
          this.props.updateExpense(expense.expenseId, { contributorIds: [...expense.contributorIds, value.userId] });
        }
      } else {
        // remove from list
        const i = expense.contributorIds.findIndex((v) => v === value.userId);
        if (i !== -1) {
          expense.contributorIds.splice(i, 1);
          this.props.updateExpense(expense.expenseId, { contributorIds: expense.contributorIds });
        }
      }
    } else {
      this.props.updateExpense(expense.expenseId, { [field]: value });
    }
  }

  removeSingleExpense(expenseId: string, expenseName: string) {
    /* eslint-disable */
    if (window.confirm(`To spowoduje usunięcie wydatku: ${expenseName}.\nKontynuować?`)) {
      this.props.removeExpense(expenseId);
    }
  }

  render() {
    const { expenses } = this.props.expenses;
    const { users } = this.props.session;

    return (
      <div style={{ marginLeft: '25px', marginRight: '25px', marginBottom: '20px' }}>
        <table className="expenses-table">
          <thead>
            <tr>
              <th style={{ minWidth: '25px' }}></th>
              <th style={{ minWidth: '200px' }}>Wydatek</th>
              <th>Kto płacił?</th>
              <th style={{ minWidth: '100px' }}>Koszt</th>
              {users.map((u) => (
                <th key={u.userId}>{u.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {expenses.map((expense) => (
              <tr key={expense.expenseId}>
                <td>
                  <button
                    className="button-no-button" type="button"
                    onClick={() => this.removeSingleExpense(expense.expenseId, expense.name)}
                    style={{ marginRight: '20px', marginLeft: '-5px', marginTop: '3px' }}>
                    <img src="../assets/thrashb.svg" width="20px" alt="thrash" />
                  </button>
                </td>
                <td style={{ textAlign: 'left' }}>
                  <InlineEdit
                    text={expense.name}
                    minWidth={15}
                    onSetText={(text: string) => this.updateField(expense, 'name', text)}
                  />
                </td>
                <td>
                  <DropdownEdit
                    items={users.map(({ userId, name }) => ({ key: userId, name }))}
                    onChange={(newId) => this.updateField(expense, 'payingUser', newId)}
                    preselected={expense.payingUser.userId}
                  />
                </td>
                <td style={{ textAlign: 'right' }}>
                  <InlineEdit
                    text={expense.amount.toFixed(2)}
                    onSetText={(text: string) => this.updateField(expense, 'amount', parseFloat(text))}
                    maxLength={9}
                  />
                  {' '}
                  zł

                </td>
                {users.map((user) => (
                  <td key={user.userId}>
                    <Checkbox
                      checked={expense.contributorIds.includes(user.userId)}
                      onChange={
                        (v: boolean) => this.updateField(expense, 'contributor', { userId: user.userId, newVal: v })
                      }
                    />
                  </td>
                ))}
              </tr>
            ))}
            <AddNewInlineEdit
              users={users}
              createExpense={this.props.createExpense}
              sessionId={this.props.session.sessionId}
            />
          </tbody>
        </table>
      </div>
    );
  }
}

export default connector(ExpensesTab);
