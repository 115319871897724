/* eslint-disable no-fallthrough */
/* eslint-disable no-param-reassign */
import {
  SessionState,
  SessionActionTypes,
  FETCH_SESSION_REQUEST,
  FETCH_SESSION_SUCCESS,
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SUCCESS,
  FetchSessionRequestAction,
  FetchSessionSuccessAction,
  UPDATE_SESSION_REQUEST,
  UPDATE_SESSION_SUCCESS,
  UpdateSessionSuccessAction,
  CREATE_USER_SUCCESS,
  CREATE_USER_REQUEST,
  CreateUserSuccessAction,
  REMOVE_USER_SUCCESS,
  RemoveUserSuccessAction,
} from './types';

const INITIAL_STATE: SessionState = {
  networkState: { isFetching: false, isFetched: false },
  sessionId: '',
  users: [],
  name: '',
  description: '',
  // ToDo: settlement:[{debtor: "Jacek", amount:100, creditor:"Patrycja"}]
};

const sessionReducer = (
  state = INITIAL_STATE,
  action: SessionActionTypes,
): SessionState => {
  switch (action.type) {
    case FETCH_SESSION_REQUEST:
    {
      const action_ = action as FetchSessionRequestAction;
      return {
        ...state,
        networkState: { isFetching: true, isFetched: false },
        sessionId: action_.sessionId,
      };
    }
    case FETCH_SESSION_SUCCESS:
    {
      const action_ = action as FetchSessionSuccessAction;
      return {
        ...state,
        networkState: { isFetching: false, isFetched: true },
        sessionId: action_.sessionId,
        users: [...action_.users],
        name: action_.name,
        description: action_.description,
      };
    }
    case UPDATE_SESSION_REQUEST:
      return state;
    case UPDATE_SESSION_SUCCESS:
    {
      const action_ = action as UpdateSessionSuccessAction;

      return {
        ...state,
        name: action_.session.name,
        description: action_.session.description,
      };
    }

    // create user
    case CREATE_USER_SUCCESS:
    {
      const action_ = action as CreateUserSuccessAction;
      return {
        ...state,
        users: [...state.users, action_.user],
      };
    }

    // remove
    case REMOVE_USER_SUCCESS:
    {
      const action_ = action as RemoveUserSuccessAction;
      const old = { ...state };
      const index = state.users.findIndex((v) => v.userId === action_.userId);
      if (index !== -1) {
        old.users.splice(index, 1);
        return {
          ...state,
          users: [...old.users],
        };
      }
      return state;
    }

    case CREATE_USER_REQUEST:
    case CREATE_SESSION_REQUEST:
    case CREATE_SESSION_SUCCESS:
    default:
      return state;
  }
};

export default sessionReducer;
