import { Expense } from '../model';
import { TransferState } from '../../../utils/backendUtil';

const prefix = 'expenses/';

// Fetching all expenses from backend
export const FETCH_EXPENSES_REQUEST = `${prefix}FETCH_EXPENSES_REQUEST`;
export const FETCH_EXPENSES_FAILURE = `${prefix}FETCH_EXPENSES_FAILURE`;
export const FETCH_EXPENSES_SUCCESS = `${prefix}FETCH_EXPENSES_SUCCESS`;

export interface FetchExpensesRequestAction {
  type: typeof FETCH_EXPENSES_REQUEST;
}

export interface FetchExpensesFailureAction {
  type: typeof FETCH_EXPENSES_FAILURE;
}

export interface FetchExpensesSuccessAction {
  type: typeof FETCH_EXPENSES_SUCCESS;
  expenses: Expense[];
}

// Updating single expense
export const UPDATE_EXPENSE_REQUEST = `${prefix}UPDATE_EXPENSE_REQUEST`;
export const UPDATE_EXPENSE_FAILURE = `${prefix}UPDATE_EXPENSE_FAILURE`;
export const UPDATE_EXPENSE_SUCCESS = `${prefix}UPDATE_EXPENSE_SUCCESS`;

export interface UpdateExpenseRequestAction {
  type: typeof UPDATE_EXPENSE_REQUEST;
  expenseId: string;
  data: any;
}

export interface UpdateExpenseFailureAction {
  type: typeof UPDATE_EXPENSE_FAILURE;
}

export interface UpdateExpenseSuccessAction {
  type: typeof UPDATE_EXPENSE_SUCCESS;
  expense: Expense;
}

// Creating single expense
export const CREATE_EXPENSE_REQUEST = `${prefix}CREATE_EXPENSE_REQUEST`;
export const CREATE_EXPENSE_FAILURE = `${prefix}CREATE_EXPENSE_FAILURE`;
export const CREATE_EXPENSE_SUCCESS = `${prefix}CREATE_EXPENSE_SUCCESS`;

export interface CreateExpensRequestAction {
  type: typeof CREATE_EXPENSE_REQUEST;
  sessionId: string;
  expenseId: string;
  name: string;
  contributorIds: string[];
  amount: number;
}

export interface CreateExpensFailureAction {
  type: typeof CREATE_EXPENSE_FAILURE;
}

export interface CreateExpensSuccessAction {
  type: typeof CREATE_EXPENSE_SUCCESS;
  expense: Expense;
}

// removing expense
export const REMOVE_EXPENSE_REQUEST = `${prefix}REMOVE_EXPENSE_REQUEST`;
export const REMOVE_EXPENSE_FAILURE = `${prefix}REMOVE_EXPENSE_FAILURE`;
export const REMOVE_EXPENSE_SUCCESS = `${prefix}REMOVE_EXPENSE_SUCCESS`;

export interface RemoveExpenseRequestAction {
  type: typeof REMOVE_EXPENSE_REQUEST;
  expenseId: string;
}

export interface RemoveExpenseFailureAction {
  type: typeof REMOVE_EXPENSE_FAILURE;
}

export interface RemoveExpenseSuccessAction {
  type: typeof REMOVE_EXPENSE_SUCCESS;
  expenseId: string;
}

// All types related to expenses
export type ExpensesActionTypes =
  | FetchExpensesRequestAction
  | FetchExpensesFailureAction
  | FetchExpensesSuccessAction
  | CreateExpensRequestAction
  | CreateExpensFailureAction
  | CreateExpensSuccessAction
  | UpdateExpenseRequestAction
  | UpdateExpenseFailureAction
  | UpdateExpenseSuccessAction
  | RemoveExpenseRequestAction
  | RemoveExpenseFailureAction
  | RemoveExpenseSuccessAction;

export interface ExpensesState {
  networkState: TransferState;
  expenses: Expense[];
}
