/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable func-names */
import { push } from 'connected-react-router';
import {
  SessionActionTypes,
  FETCH_SESSION_SUCCESS,
  FETCH_SESSION_REQUEST,
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SUCCESS,
  UPDATE_SESSION_REQUEST,
  UPDATE_SESSION_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  REMOVE_USER_REQUEST,
  REMOVE_USER_SUCCESS,
} from './types';
import { Session, User } from '../model';
import ApiClient from '../../../utils/apiClient';
import { addSessionLink } from '../sessionLinks';
import { fetchExpenses } from '../expenses';

function requestSession(sessionId: string): SessionActionTypes {
  return {
    type: FETCH_SESSION_REQUEST,
    sessionId,
  };
}

function receiveSession(
  sessionId: string,
  data: {
    users: User[];
    name: string;
    description: string;
  },
): SessionActionTypes {
  return {
    type: FETCH_SESSION_SUCCESS,
    sessionId,
    users: data.users,
    name: data.name,
    description: data.description,
  };
}

// Thunk action creator
export function fetchSession(sessionId: string) {
  return function (dispatch: any) {
    dispatch(requestSession(sessionId));

    return ApiClient.readSession(sessionId).then((response) => {
      dispatch(receiveSession(sessionId, response.data));
      dispatch(
        addSessionLink({ sessionId, name: response.data.name }),
      );
    }).catch((error) => {
      dispatch(push('/404'));
    });
  };
}

// create session
function requestSessionCreation(
  name: string,
  description: string,
): SessionActionTypes {
  return {
    type: CREATE_SESSION_REQUEST,
    name,
    description,
  };
}

function receiveCreatedSession(sessionId: string): SessionActionTypes {
  return {
    type: CREATE_SESSION_SUCCESS,
    sessionId,
  };
}

// Thunk action creator
export function createSession(name: string, description: string) {
  return function (dispatch: any) {
    dispatch(requestSessionCreation(name, description));

    return ApiClient.createSession(name, description).then((response) => {
      dispatch(receiveCreatedSession(response.data.sessionId));
      // add link
      dispatch(
        addSessionLink({
          name,
          sessionId: response.data.sessionId as string,
        }),
      );
      return response.data.sessionId;
    });
  };
}

// Update session
function requestSessionUpdate(sessionId: string, data: any): SessionActionTypes {
  return {
    type: UPDATE_SESSION_REQUEST,
    sessionId,
    data,
  };
}

function sessionUpdateSuccess(session: Session): SessionActionTypes {
  return {
    type: UPDATE_SESSION_SUCCESS,
    session,
  };
}

export function updateSession(sessionId: string, data: any) {
  return function (dispatch: any) {
    dispatch(requestSessionUpdate(sessionId, data));

    return ApiClient
      .updateSession(sessionId, data)
      .then((response) => dispatch(sessionUpdateSuccess(response.data)));
  };
}

// create user
function requestUserCreation(
  name: string,
  sessionId: string,
): SessionActionTypes {
  return {
    type: CREATE_USER_REQUEST,
    name,
    sessionId,
  };
}

function receiveCreatedUser(user: User): SessionActionTypes {
  return {
    type: CREATE_USER_SUCCESS,
    user,
  };
}

// Thunk action creator
export function createUser(name: string, sessionId: string) {
  return function (dispatch: any) {
    dispatch(requestUserCreation(name, sessionId));

    return ApiClient.createUser(name, sessionId).then((response) => {
      dispatch(receiveCreatedUser({ name: response.data.name, userId: response.data.userId }));
    });
  };
}

// remove user
function requestUserRemoval(
  userId: string,
): SessionActionTypes {
  return {
    type: REMOVE_USER_REQUEST,
    userId,
  };
}

function receiveRemovedUser(userId: string): SessionActionTypes {
  return {
    type: REMOVE_USER_SUCCESS,
    userId,
  };
}

// Thunk action creator
export function removeUser(sessionId: string, userId: string) {
  return function (dispatch: any) {
    dispatch(requestUserRemoval(userId));

    return ApiClient.removeUser(userId).then((response) => {
      dispatch(receiveRemovedUser(userId));
      // this action may remove expenses as well so refresh that
      dispatch(fetchExpenses(sessionId));
    });
  };
}
